import { of } from 'rxjs';
import { Regions } from '@parashift/shared/constants';

export class RegionService {
  region: Regions = 'ZZ';

  setRegion(region: Regions) {
    this.region = region;
    return of(true);
  }
}
