import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceBase, JsonapiBaseService } from '@parashift/ngx-airbrush';
import 'reflect-metadata';
import { JsonApiQueryData } from './jsonapi-query-data.model';
import { ErrorResponse } from './error-response.model';
import { QueryParamsService } from './../../query-params.service';
import { ServiceDecoratorOptions, ServiceMetadata } from './../decorators';
import { UrlVars } from '@parashift/shared/models';
import { Endpoint, EnvService } from '../../env.service';

export abstract class BaseApiService<T extends ResourceBase> extends JsonapiBaseService<T> {
  apiVersion: string;
  baseUrl: Endpoint;

  protected environment = inject(EnvService);

  private urlVars: UrlVars;
  private tenant_id: number;

  constructor(public http: HttpClient, public queryParamsService: QueryParamsService) {
    super(http);
  }

  public findAll(listFilter?: any, tenant_id?: number, urlVars?: UrlVars): Observable<JsonApiQueryData<T>> {
    this.setProperties(tenant_id, urlVars);
    const httpParams = this.queryParamsService.getParams(listFilter, false, true);

    return this.all({ params: httpParams, metadata: true }).pipe(
      map((result) => new JsonApiQueryData(result.data, { meta: result.meta }))
    );
  }

  public findRecord(id: string, listFilter?: any, tenant_id?: number, urlVars?: UrlVars): Observable<T> {
    this.setProperties(tenant_id, urlVars);
    const httpParams = this.queryParamsService.getParams(listFilter, false, true);
    return this.find(id, httpParams);
  }

  public saveRecord(model: T, tenant_id?: number, urlVars?: UrlVars): Observable<T> {
    this.setProperties(tenant_id, urlVars);
    return this.save(model);
  }

  public deleteRecord(id: string, tenant_id?: number, urlVars?: UrlVars): Observable<any> {
    this.setProperties(tenant_id, urlVars);
    return this.delete(id);
  }

  public getServiceConfig(): ServiceDecoratorOptions {
    return Reflect.getMetadata(ServiceMetadata, this.className);
  }

  protected setProperties(tenant_id: number, urlVars?: UrlVars) {
    this.tenant_id = tenant_id;
    this.urlVars = urlVars;
  }

  private replaceUrlVars(url: string): string {
    if (url && this.urlVars) {
      for (const key in this.urlVars) {
        if (Object.prototype.hasOwnProperty.call(this.urlVars, key)) {
          const substr = ':' + key;
          url = url.replace(new RegExp(substr, 'g'), this.urlVars[key]);
        }
      }
    }

    return url;
  }

  protected handleError(error: any): Observable<any> {
    if (
      error instanceof HttpErrorResponse &&
      error.error instanceof Object &&
      error.error.errors &&
      error.error.errors instanceof Array
    ) {
      const errors: ErrorResponse = new ErrorResponse(error);
      return throwError(() => errors);
    }

    return throwError(() => error);
  }

  protected buildUrl(id?: string): string {
    const serviceConfig = this.getServiceConfig();
    const modelConfig = this.getModelConfig();
    const baseUrl = serviceConfig.baseUrl || this.environment.endpoints[this.baseUrl];
    const apiVersion = (this.urlVars && this.urlVars.api_version) || serviceConfig.apiVersion || this.apiVersion || 'v1';
    const tenant_id = this.tenant_id;
    const beforeEndpointUrl: string = serviceConfig.beforeEndpointUrl || (this.urlVars && this.urlVars.before_endpoint_url);
    const endpointUrl: string = serviceConfig.endpointUrl || modelConfig.type;
    const afterEndpointUrl: string = serviceConfig.afterEndpointUrl || (this.urlVars && this.urlVars.after_endpoint_url);
    const postfix: string = serviceConfig.postfix || (this.urlVars && this.urlVars.postfix);

    let url: string = [baseUrl, apiVersion, tenant_id, beforeEndpointUrl, endpointUrl, afterEndpointUrl, id, postfix].filter(itm => !!itm).join('/');
    url = this.replaceUrlVars(url);
    this.urlVars = undefined;

    return url;
  }
}
